'use client';

import type { PropsWithChildren, JSX } from 'react';
import { SWRConfig, type SWRConfiguration } from 'swr';

/**
 * This component is a Higher Order Component (HOC) that wraps around the SWRConfig component from the SWR library.
 * It provides a global configuration for all SWR hooks in the component tree.
 * The `fallback` prop is used to provide initial data for SWR hooks when the data is not yet fetched.
 * The `dedupingInterval` is set to 3000 milliseconds, which means SWR will avoid duplicate requests within this time frame.
 *
 * @param {object} props - The props that are passed to the component.
 * @param {ReactNode} props.children - The child components to render.
 * @param {any} props.fallback - The initial data to return for SWR hooks.
 * @returns {JSX.Element} The SWRConfig component with the provided configuration.
 */
export const LoaderSwrConfig = ({
  children,
  fallback,
}: PropsWithChildren<{ fallback: SWRConfiguration['fallback'] }>): JSX.Element => (
  <SWRConfig value={{ fallback, dedupingInterval: 3000 }}>{children}</SWRConfig>
);
