import { LinkButton } from '@packages/shared/src/components/Button/LinkButton';
import { Radio } from '@packages/shared/src/components/Radio';
import { Stack } from '@packages/shared/src/components/Stack';
import { Typography } from '@packages/shared/src/components/Typography';
import { Box } from '@packages/shared/src/components/Box';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import { messages } from '../PromotionBanner.messages';
import type { AccordionPromotion } from '../types';
import { PromotionAccordionTemplate } from './PromotionAccordionTemplate';

export const PromotionAsChoiceLayer = ({
  activePromotion,
  selectedPromotion,
  setActiveCode,
}: {
  activePromotion: AccordionPromotion;
  selectedPromotion: AccordionPromotion;
  setActiveCode: (
    activeCode: string,
    textsCode: string,
    isPaybackPromotion: boolean,
  ) => Promise<void>;
}) => {
  const { formatMessage } = useIntl();
  const { styleVariant } = useConfig();
  const [choiceVariant, setChoiceVariant] = useState<string>('selected');
  const [isExpanded, setIsExpanded] = useState(true);
  const dispatchGTMEvent = useTracking();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChoiceVariant(event.target.value);

    dispatchGTMEvent({
      event: 'trackPromoBanner',
      eventLabel: 'promo-banner',
      eventValue: `choice_layer_changed_${
        event.target.value === 'active' ? activePromotion.name : selectedPromotion.name
      }`,
    });
  };

  return (
    <PromotionAccordionTemplate
      defaultExpanded
      isExpanded={isExpanded}
      toggleFunction={() => setIsExpanded(!isExpanded)}
      isPaybackPromotion={!!selectedPromotion.isPaybackPromotion}
      summaryContent={formatMessage(messages.choiceSummary)}
      detailscontent={
        <Stack alignItems={styleVariant === 'dark' ? 'left' : 'center'}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'bold',
            }}
            paragraph
          >
            {formatMessage(messages.choiceSelect, { br: <br /> })}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormControlLabel
              value="active"
              control={
                <Radio
                  checked={choiceVariant === 'active'}
                  onChange={handleChange}
                  value="active"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': activePromotion.title }}
                  id="active-promotion"
                />
              }
              label={activePromotion.title}
              key="label-active"
            />
            <FormControlLabel
              value="selected"
              control={
                <Radio
                  checked={choiceVariant === 'selected'}
                  onChange={handleChange}
                  value="selected"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': selectedPromotion.title }}
                  id="selected-promotion"
                />
              }
              label={selectedPromotion.title}
              key="label-selected"
            />
          </Box>
          <LinkButton
            color="inherit"
            onClick={(event) => {
              event.preventDefault();
              const promotionToActivate =
                choiceVariant === 'active' ? activePromotion : selectedPromotion;
              setActiveCode(
                promotionToActivate.cartCode ?? '',
                promotionToActivate.textsCode,
                promotionToActivate.isPaybackPromotion,
              );
              dispatchGTMEvent({
                event: 'trackPromoBanner',
                eventLabel: 'promo-banner',
                eventValue: `choice_layer_changed_${promotionToActivate.name}`,
              });
            }}
            sx={{
              mt: 3.6,
              ...(styleVariant === 'dark' && {
                justifyContent: 'left',
              }),
            }}
          >
            {formatMessage(messages.choicePersist)}
          </LinkButton>
        </Stack>
      }
    />
  );
};
