import type { Dispatch, SetStateAction } from 'react';
// import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { remotePromotion } from '../../atoms';
import type { Promotion, PromotionVariantsData } from './types';
import { useSetActiveCode } from './utils/useSetActiveCode';
import {
  useShouldAdHocActivateCode,
  shouldRenderAsChoiceLayer,
  useShouldRemoveActiveExpiredCode,
} from './utils/conditions';
import { PromotionAsAccordion } from './variants/PromotionAsAccordion';
import { PromotionAsLink } from './variants/PromotionAsLink';
import { PromotionAsChoiceLayer } from './variants/PromotionAsChoiceLayer';

// const DynamicPromotionAsAccordion = dynamic(
//   () =>
//     import(
//       /* webpackChunkName: 'CMS_PromotionAsAccordion' */ './variants/PromotionAsAccordion'
//     ).then((mod) => mod.PromotionAsAccordion),
//   { ssr: false },
// );

// const DynamicPromotionAsLink = dynamic(
//   () =>
//     import(/* webpackChunkName: 'CMS_PromotionAsLink' */ './variants/PromotionAsLink').then(
//       (mod) => mod.PromotionAsLink,
//     ),
//   { ssr: false },
// );

// const DynamicPromotionAsChoiceLayer = dynamic(
//   () =>
//     import(
//       /* webpackChunkName: 'CMS_PromotionAsChoiceLayer' */ './variants/PromotionAsChoiceLayer'
//     ).then((mod) => mod.PromotionAsChoiceLayer),
//   { ssr: false },
// );

/**
 * The Promotionbanner-Content-Wrapper renders the corresponding variant by the delivered data
 *
 * @param promotionData Object of shop, active and/or selected promotion data
 * - shop: promotionData by shopId e.g. storefront, damenmode, ...
 * - active: promotionData by currently user-active Bonuscode (read from session/cookie)
 * - selected: promotionData by user-selected Bonuscode - this can be query-Params ?BonusCode or ?P1 (base64 encoded bonuscode)
 * @returns JSX.Element to be used in PromotionBanner-Component
 */
export const PromotionBannerContentWrapper = ({
  promotionData: { shop, active, selected },
  setShowAsPaybackPromotion,
}: {
  promotionData: PromotionVariantsData;
  setShowAsPaybackPromotion: Dispatch<SetStateAction<Promotion['isPaybackPromotion']>>;
}) => {
  const dispatchGTMEvent = useTracking();
  const { language, defaultLanguage } = useI18n();
  const [activeData, setActiveData] = useState<Promotion | undefined>(
    !active?.isExpired ? active : undefined,
  );
  const [selectedQueryData, setSelectedQueryData] = useState<Promotion | undefined>(
    !selected?.isExpired ? selected : undefined,
  );
  const [selectedRemoteData, setSelectedRemoteData] = useAtom(remotePromotion);

  // INSPIRE-3618 - update the data by language switch
  useEffect(() => {
    setActiveData(!active?.isExpired ? active : undefined);
  }, [active]);
  useEffect(() => {
    setSelectedQueryData(!selected?.isExpired ? selected : undefined);
  }, [selected]);

  const selectedData =
    (selectedRemoteData && !selectedRemoteData?.isExpired && selectedRemoteData) ||
    selectedQueryData;
  /** INSPIRE-3358 use a cloned object here to prevent changes on promoDataForRender
   * (title changes on redeem code) has an effect on choiceLayer output */
  const promoDataForRender = structuredClone(selectedData || activeData || shop);
  useEffect(() => {
    setShowAsPaybackPromotion(!!promoDataForRender?.isPaybackPromotion);
  }, [promoDataForRender?.isPaybackPromotion, setShowAsPaybackPromotion]);
  const [isActive, setIsActive] = useState<boolean>(
    promoDataForRender?.textsCode === activeData?.textsCode,
  );
  const [showAccordionOpen, setShowAccordionOpen] = useState<boolean>(false);

  /* the choiceLayer and the accordion promotion can change the active code
   - this code has to be registered as active and the UI must respond to the changed information */
  const setActiveCode = useSetActiveCode(
    selectedData,
    shop,
    language || defaultLanguage,
    setActiveData,
    setShowAccordionOpen,
    setSelectedQueryData,
    setSelectedRemoteData,
    setIsActive,
  );

  const shouldRemoveActiveExpiredCode = useShouldRemoveActiveExpiredCode({
    selectedData,
    promoDataForRender,
  });
  const shouldAdHocActivateCode = useShouldAdHocActivateCode({
    activeData,
    selectedData,
    promoDataForRender,
  });

  useEffect(() => {
    // NJS-1253 get rid of active expired code
    if (shouldRemoveActiveExpiredCode) {
      setActiveCode(null, null);
    }

    // ad hoc activate the valid selected code (by remote promotion or query param) if there is currently no active code in cart
    if (promoDataForRender && shouldAdHocActivateCode) {
      setActiveCode(
        promoDataForRender.cartCode,
        promoDataForRender.textsCode,
        promoDataForRender.isPaybackPromotion,
      );
      if ('feedbackTitle' in promoDataForRender) {
        promoDataForRender.title = promoDataForRender.feedbackTitle || promoDataForRender.title;
      }
    }
  }, [promoDataForRender, setActiveCode, shouldAdHocActivateCode, shouldRemoveActiveExpiredCode]);

  if (!promoDataForRender) {
    return null;
  }

  const { name } = promoDataForRender;

  /**
   * DISPLAYING THE PROMOTION AS ...
   */

  /* ... CHOICE-LAYER when there was already an activated code
   in cart but additionally there is a selected code by query-params */
  if (
    // check for card codes here to give typescript the correct hint that they can be placed into the choiceLayer
    activeData?.cartCode &&
    selectedData?.cartCode &&
    shouldRenderAsChoiceLayer({
      selectedQueryData,
      selectedRemoteData,
      isActive,
      selectedData,
      activeData,
    })
  ) {
    dispatchGTMEvent({
      event: 'trackPromoBanner',
      eventLabel: 'promo-banner',
      eventValue: `choice_layer_open_${name}`,
    });

    return (
      <PromotionAsChoiceLayer
        activePromotion={activeData}
        selectedPromotion={selectedData}
        setActiveCode={setActiveCode}
        key="promotionAsChoiceLayer"
      />
    );
  }

  dispatchGTMEvent({
    event: 'trackPromoBanner',
    eventLabel: 'promo-banner',
    eventValue: `promobanner_displayed_${name}`,
  });

  /* ... LINK when the promotion-data serve a link */
  if (
    promoDataForRender.promotionAsLink &&
    promoDataForRender.linkReady &&
    promoDataForRender.title
  ) {
    return <PromotionAsLink key="promotionAsLink" data={promoDataForRender} />;
  }

  /* ... ACCORDION as default */
  return (
    <PromotionAsAccordion
      {...promoDataForRender}
      isActive={isActive}
      setActivePromotionCode={setActiveCode}
      // the changed key for ad-hoc-activation is needed to reset the isExpanded state in the accordion
      key={`promotionAsAccordion-${showAccordionOpen}`}
      defaultExpanded={showAccordionOpen}
    />
  );
};
