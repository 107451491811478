import { useEffect, type JSX } from 'react';
import { useTrackCampaignOnLoad } from '@packages/shared/src/hooks/useTrackCampaign/useTrackCampaign';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { Link } from '@packages/shared/src/components/Link/Link';
import { useAssignLinkTargetBasedOnBotStatus } from '../../../hooks/useAssignLinkTargetBasedOnBotStatus';
import { PromotionBannerTestIds } from '../PromotionBannerTestIds';
import type { LinkPromotion } from '../types';

export const PromotionAsLink = ({ data }: { data: LinkPromotion }): JSX.Element => {
  const { trackClick, trackDisplay } = useTrackCampaignOnLoad(data);
  const { styleVariant } = useConfig();
  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(data.linkReady);

  useEffect(() => {
    trackDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Link
      href={finalLink}
      data-testid={PromotionBannerTestIds.asLink}
      noLinkStyle
      onClick={() => trackClick()}
      sx={{ ...(styleVariant === 'dark' && { ml: 2, fontWeight: 'bold' }) }}
    >
      {data.title}
    </Link>
  );
};
